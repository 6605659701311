import { useLocalStorage } from 'usehooks-ts';
import { NextRouter, useRouter } from 'next/router';
import { LinkWrapperProps, NavSection, UiKit } from '@datavant/uikit';
import React from 'react';
import Link from 'next/link';
import { RecordHubNavIcons } from '@datavant/idsb-shared/components/AllIcons';
import { CurrentSmartRequestUser, useUser } from '../lib/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const QuickSearchIcon = (): JSX.Element => <FontAwesomeIcon icon={UiKit.Icons.FontAwesome.solid.faSearch} />;

const quickSearchLabel = (
    <>
        {'Quick Search'}
        <UiKit.Chakra.HStack paddingLeft={'5px'}>
            <UiKit.Chakra.Tooltip
                label={'NEW!'}
                color={UiKit.consistency.white}
                backgroundColor={UiKit.consistency.gray80}
                arrowSize={5}
                padding={'4px'}
                borderRadius={UiKit.consistency.borderRadius}
            >
                {<FontAwesomeIcon icon={UiKit.Icons.FontAwesome.solid.faCircleExclamation} />}
            </UiKit.Chakra.Tooltip>
        </UiKit.Chakra.HStack>
    </>
);

const attorneyRequestorTools = (user: CurrentSmartRequestUser, router: NextRouter): NavSection => {
    return {
        items: [
            {
                icon: RecordHubNavIcons.Registration,
                label: 'Registration',
                path: '/request/registration-validation/all',
                displayRule: () => user.superAdmin,
            },
            {
                icon: QuickSearchIcon,
                label: quickSearchLabel as unknown as string,
                path: '/request/quick-search',
                displayRule: () => user.clientSideFlags.showQuickSearch,
            },
            {
                icon: RecordHubNavIcons.Requests,
                label: 'All Requests',
                path: '/',
                displayRule: () => true,
            },
            {
                icon: RecordHubNavIcons.AddNewRequest,
                label: 'Create Request',
                path: '/request/create',
                displayRule: () => user.addNewRequest && !belongsOnlyToCompanyType(user, 'PRO'),
            },
            {
                icon: RecordHubNavIcons.BatchDownload,
                label: 'Batch Download',
                path: '/request/batch-download',
                displayRule: () => true,
            },
            {
                icon: RecordHubNavIcons.InvoicesAndPayment,
                label: 'Invoices & Billing',
                path: '/request/invoices',
                displayRule: () => user.payment,
            },
            {
                icon: RecordHubNavIcons.Users,
                label: 'Users',
                path: '/request/users',
                displayRule: () => true,
            },
            {
                icon: RecordHubNavIcons.MySettings,
                label: 'My Settings',
                path: '/request/settings',
                displayRule: () => true,
            },
            {
                icon: RecordHubNavIcons.InternalReports,
                label: 'Okta Tools',
                path: '/request/okta-tools',
                displayRule: () => user.clientSideFlags.oktaToolsAccess,
            },
        ]
            .filter(item => item.displayRule())
            .map(({ icon, path, label, displayRule }) => ({
                kind: 'NavItemLink',
                icon,
                label,
                isActivePage: router.asPath === path,
                path,
                displayRule,
            })),
    };
};

const NextLink: React.FC<LinkWrapperProps> = (props: LinkWrapperProps): JSX.Element => {
    return (
        <Link
            href={{
                pathname: props.path,
            }}
            passHref={true}
            legacyBehavior={true}
        >
            <a>{props.children}</a>
        </Link>
    );
};

export const SRSideNav = (): JSX.Element => {
    const [isOpen, setIsOpen] = useLocalStorage('sr-sidebar-open', true);

    const router = useRouter();
    const { user } = useUser();

    if (!user) {
        return <></>;
    }

    if (user?.clientSideFlags?.showMaintenancePage) {
        return <></>;
    }

    return (
        <UiKit.Nav
            linkWrapperComponent={NextLink}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            navTop={{
                sections: user?.active ? [attorneyRequestorTools(user, router)] : [],
            }}
            navBottom={{}}
        />
    );
};

export const belongsOnlyToCompanyType = (user: CurrentSmartRequestUser, companyType: string): boolean => {
    return user.companies.every(company => company.majorClass === companyType);
};
